<template>
  <div class="module1-part1-infography">
    <!-- DETAIL DES AIDES -->
    <div class="main-drawer-wrapper">
      <drawer>
        <template v-slot:head="{ isOpen }">
          <div class="main-drawer-head primary" :class="{ open: isOpen }">
            <p class="title">{{ $t('module1.part2.infography.block1.title') }}</p>
            <div class="arrow">
              <div class="icon" v-html="require('@/assets/icons/right-herringbone.svg?inline')"></div>
            </div>
          </div>
        </template>
        <template v-slot:content>
          <div class="main-drawer-content primary">
            <!-- AIDES ETAT -->
            <drawer>
              <template v-slot:head="{ isOpen }">
                <div class="sub-drawer-head aides-etat" :class="{ open: isOpen }">
                  <p class="sub-drawer-count">1</p>
                  <p class="title">{{ $t('module1.part2.infography.block1.aidesEtat.title') }}</p>
                  <div class="arrow">
                    <div class="icon" v-html="require('@/assets/icons/right-herringbone.svg?inline')"></div>
                  </div>
                </div>
              </template>
              <template v-slot:content>
                <div class="sub-drawer-content aides-etat primary">
                  <app-tabs :tabs="aidesEtatTabs" class="sub-drawer-tabs">
                    <template v-slot:tab="{ alt, image, isCurrent }">
                      <div class="aide-tab-wrapper" :class="{ current: isCurrent }">
                        <div class="aide-tab">
                          <img :src="image" :alt="alt">
                        </div>
                      </div>
                    </template>
                    <template v-slot:content="{ name }">
                      <div v-if="name === 'mpr'">
                        <div class="section">
                          <p class="section-content">
                            {{ $t('module1.part2.infography.block1.aidesEtat.maprimerenov.intro') }}
                          </p>
                        </div>
                        <div class="section">
                          <div class="section-head">
                            <img :src="require('@/assets/module1/part2/lesson/logement-ico.svg')" :alt="$t('global.imageAlts.logementIllu')">
                            <p class="label" >{{ $t('module1.part2.infography.block1.titles.logement') }}</p>
                          </div>
                          <div class="section-content" v-html="$t('module1.part2.infography.block1.aidesEtat.maprimerenov.logement')"></div>
                        </div>
                        <div class="section">
                          <div class="section-head">
                            <img :src="require('@/assets/module1/part2/lesson/qui-ico.svg')" :alt="$t('global.imageAlts.quiIllu')">
                            <p class="label" >{{ $t('module1.part2.infography.block1.titles.qui') }}</p>
                          </div>
                          <div class="section-content" v-html="$t('module1.part2.infography.block1.aidesEtat.maprimerenov.qui')"></div>
                        </div>
                        <div class="section">
                          <div class="section-head">
                            <img :src="require('@/assets/module1/part2/lesson/money-ico.svg')" :alt="$t('global.imageAlts.moneyIllu')">
                            <p class="label" >{{ $t('module1.part2.infography.block1.titles.montant') }}</p>
                          </div>
                          <div class="section-content">
                            <p class="left-content" v-html="$t('module1.part2.infography.block1.aidesEtat.maprimerenov.montant1')"></p>
                            <div class="list-wrapper">
                              <div class="flex-icon-block">
                                <img :src="require('@/assets/module1/part2/lesson/tres-modeste-ico.svg')"/>
                                <p>{{ $t('module1.part2.infography.block1.aidesEtat.maprimerenov.montantPlafond1') }}</p>
                              </div>
                              <div class="flex-icon-block">
                                <img :src="require('@/assets/module1/part2/lesson/modeste-ico.svg')"/>
                                <p>{{ $t('module1.part2.infography.block1.aidesEtat.maprimerenov.montantPlafond2') }}</p>
                              </div>
                              <div class="flex-icon-block">
                                <img :src="require('@/assets/module1/part2/lesson/intermediaire-ico.svg')"/>
                                <p>{{ $t('module1.part2.infography.block1.aidesEtat.maprimerenov.montantPlafond3') }}</p>
                              </div>
                              <div class="flex-icon-block">
                                <img :src="require('@/assets/module1/part2/lesson/aise-ico.svg')"/>
                                <p>{{ $t('module1.part2.infography.block1.aidesEtat.maprimerenov.montantPlafond4') }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="section">
                          <div class="section-head">
                            <img :src="require('@/assets/module1/part2/lesson/outils-ico.svg')" :alt="$t('global.imageAlts.travauxIllu')">
                            <p class="label" >{{ $t('module1.part2.infography.block1.titles.travaux') }}</p>
                          </div>
                          <div class="section-content">
                            <p class="left-content" v-html="$t('module1.part2.infography.block1.aidesEtat.maprimerenov.travauxIntro')"></p>
                            <div class="list-wrapper">
                              <div class="flex-icon-block">
                                <img :src="require('@/assets/module1/part2/lesson/isolation-ico.svg')"/>
                                <p class="big-text">{{ $t('module1.part2.infography.block1.travaux.isol') }}</p>
                              </div>
                              <div class="flex-icon-block">
                                <img :src="require('@/assets/module1/part2/lesson/menuiserie-ico.svg')"/>
                                <p class="big-text">{{ $t('module1.part2.infography.block1.travaux.menuiserie') }}</p>
                              </div>
                              <div class="flex-icon-block">
                                <img :src="require('@/assets/module1/part2/lesson/chauffage-ico.svg')"/>
                                <p class="big-text">{{ $t('module1.part2.infography.block1.travaux.chauffage') }}</p>
                              </div>
                              <div class="flex-icon-block">
                                <img :src="require('@/assets/module1/part2/lesson/ventilation-ico.svg')"/>
                                <p class="big-text">{{ $t('module1.part2.infography.block1.travaux.vmc') }}</p>
                              </div>

                              <div class="flex-icon-block align-start">
                                <img :src="require('@/assets/module1/part2/lesson/reno-globale-ico.svg')"/>
                                <div>
                                  <p class="big-text">{{ $t('module1.part2.infography.block1.travaux.renoAmpleur') }}</p>
                                  <p class="annotation" v-html="$t('module1.part2.infography.block1.aidesEtat.maprimerenov.renoAmpleurAnnotation')"></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="section">
                          <div class="section-head">
                            <img :src="require('@/assets/module1/part2/lesson/obtention-ico.svg')" :alt="$t('global.imageAlts.obtentionIllu')">
                            <p class="label" >{{ $t('module1.part2.infography.block1.titles.obtention') }}</p>
                          </div>
                          <div class="section-content">
                            <div class="checklist">
                              <p class="item">{{ $t('module1.part2.infography.block1.aidesEtat.maprimerenov.obtention1') }}</p>
                              <p class="item">{{ $t('module1.part2.infography.block1.aidesEtat.maprimerenov.obtention2') }}</p>
                              <p class="item">{{ $t('module1.part2.infography.block1.aidesEtat.maprimerenov.obtention3') }}</p>
                              <p class="item">{{ $t('module1.part2.infography.block1.aidesEtat.maprimerenov.obtention4') }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="name === 'tva55'">
                        <div class="section">
                          <p class="section-content">
                            {{ $t('module1.part2.infography.block1.aidesEtat.tva55.intro') }}
                          </p>
                        </div>
                        <div class="section">
                          <div class="section-head">
                            <img :src="require('@/assets/module1/part2/lesson/logement-ico.svg')" :alt="$t('global.imageAlts.logementIllu')">
                            <p class="label" >{{ $t('module1.part2.infography.block1.titles.logement') }}</p>
                          </div>
                          <div class="section-content" v-html="$t('module1.part2.infography.block1.aidesEtat.tva55.logement')"></div>
                        </div>
                        <div class="section">
                          <div class="section-head">
                            <img :src="require('@/assets/module1/part2/lesson/qui-ico.svg')" :alt="$t('global.imageAlts.quiIllu')">
                            <p class="label" >{{ $t('module1.part2.infography.block1.titles.qui') }}</p>
                          </div>
                          <div class="section-content" v-html="$t('module1.part2.infography.block1.aidesEtat.tva55.qui')"></div>
                        </div>
                        <div class="section">
                          <div class="section-head">
                            <img :src="require('@/assets/module1/part2/lesson/outils-ico.svg')" :alt="$t('global.imageAlts.travauxIllu')">
                            <p class="label" >{{ $t('module1.part2.infography.block1.titles.travaux') }}</p>
                          </div>
                          <div class="section-content">
                            <p class="left-content" v-html="$t('module1.part2.infography.block1.aidesEtat.tva55.travauxIntro')"></p>
                            <div class="list-wrapper">
                              <div class="flex-icon-block">
                                <img :src="require('@/assets/module1/part2/lesson/isolation-ico.svg')"/>
                                <p class="big-text">{{ $t('module1.part2.infography.block1.travaux.isol') }}</p>
                              </div>
                              <div class="flex-icon-block">
                                <img :src="require('@/assets/module1/part2/lesson/menuiserie-ico.svg')"/>
                                <p class="big-text">{{ $t('module1.part2.infography.block1.travaux.menuiserie') }}</p>
                              </div>
                              <div class="flex-icon-block">
                                <img :src="require('@/assets/module1/part2/lesson/chauffage-ico.svg')"/>
                                <p class="big-text">{{ $t('module1.part2.infography.block1.travaux.chauffage') }}</p>
                              </div>
                              <div class="flex-icon-block">
                                <img :src="require('@/assets/module1/part2/lesson/outils-bg-ico.svg')"/>
                                <p class="big-text">{{ $t('module1.part2.infography.block1.aidesEtat.tva55.travaux1') }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="section">
                          <div class="section-head">
                            <img :src="require('@/assets/module1/part2/lesson/obtention-ico.svg')" :alt="$t('global.imageAlts.obtentionIllu')">
                            <p class="label" >{{ $t('module1.part2.infography.block1.titles.obtention') }}</p>
                          </div>
                          <div class="section-content">
                            <div class="checklist single">
                              <p class="item">{{ $t('module1.part2.infography.block1.aidesEtat.tva55.obtention') }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </app-tabs>
                </div>
              </template>
            </drawer>

            <!-- AIDES PRIVES -->
            <drawer>
              <template v-slot:head="{ isOpen }">
                <div class="sub-drawer-head aides-prives" :class="{ open: isOpen }">
                  <p class="sub-drawer-count">2</p>
                  <p class="title">{{ $t('module1.part2.infography.block1.aidesPrives.title') }}</p>
                  <div class="arrow">
                    <div class="icon" v-html="require('@/assets/icons/right-herringbone.svg?inline')"></div>
                  </div>
                </div>
              </template>
              <template v-slot:content>
                <div class="sub-drawer-content aides-etat primary">
                  <app-tabs :tabs="aidesPriveTabs" class="sub-drawer-tabs">
                    <template v-slot:tab="{ alt, image, isCurrent }">
                      <div class="aide-tab-wrapper" :class="{ current: isCurrent }">
                        <div class="aide-tab">
                          <img :src="image" :alt="alt">
                        </div>
                      </div>
                    </template>
                    <template v-slot:content="{ name }">
                      <div v-if="name === 'cee'">
                        <div class="section">
                          <div class="section-content" v-html="$t('module1.part2.infography.block1.aidesPrives.cee.intro')">
                          </div>
                        </div>
                        <div class="section">
                          <div class="section-head">
                            <img :src="require('@/assets/module1/part2/lesson/logement-ico.svg')" :alt="$t('global.imageAlts.logementIllu')">
                            <p class="label" >{{ $t('module1.part2.infography.block1.titles.logement') }}</p>
                          </div>
                          <div class="section-content" v-html="$t('module1.part2.infography.block1.aidesPrives.cee.logement')"></div>
                        </div>
                        <div class="section">
                          <div class="section-head">
                            <img :src="require('@/assets/module1/part2/lesson/qui-ico.svg')" :alt="$t('global.imageAlts.quiIllu')">
                            <p class="label" >{{ $t('module1.part2.infography.block1.titles.qui') }}</p>
                          </div>
                          <div class="section-content" v-html="$t('module1.part2.infography.block1.aidesPrives.cee.qui')"></div>
                        </div>
                        <div class="section">
                          <div class="section-head">
                            <img :src="require('@/assets/module1/part2/lesson/money-ico.svg')" :alt="$t('global.imageAlts.moneyIllu')">
                            <p class="label" >{{ $t('module1.part2.infography.block1.titles.montant') }}</p>
                          </div>
                          <div class="section-content">
                            <p v-html="$t('module1.part2.infography.block1.aidesPrives.cee.montant1')"></p>
                            <p v-html="$t('module1.part2.infography.block1.aidesPrives.cee.montant2')"></p>
                          </div>
                        </div>
                        <div class="section">
                          <div class="section-head">
                            <img :src="require('@/assets/module1/part2/lesson/outils-ico.svg')" :alt="$t('global.imageAlts.travauxIllu')">
                            <p class="label" >{{ $t('module1.part2.infography.block1.titles.travaux') }}</p>
                          </div>
                          <div class="section-content">
                            <p class="left-content" v-html="$t('module1.part2.infography.block1.aidesPrives.cee.travaux')"></p>
                            <div class="list-wrapper">
                              <div class="flex-icon-block">
                                <img :src="require('@/assets/module1/part2/lesson/isolation-ico.svg')"/>
                                <p class="big-text">{{ $t('module1.part2.infography.block1.travaux.isol') }}</p>
                              </div>
                              <div class="flex-icon-block">
                                <img :src="require('@/assets/module1/part2/lesson/menuiserie-ico.svg')"/>
                                <p class="big-text">{{ $t('module1.part2.infography.block1.travaux.menuiserie') }}</p>
                              </div>
                              <div class="flex-icon-block">
                                <img :src="require('@/assets/module1/part2/lesson/chauffage-ico.svg')"/>
                                <p class="big-text">{{ $t('module1.part2.infography.block1.travaux.chauffage') }}</p>
                              </div>
                              <div class="flex-icon-block">
                                <img :src="require('@/assets/module1/part2/lesson/ventilation-ico.svg')"/>
                                <p class="big-text">{{ $t('module1.part2.infography.block1.travaux.vmcSimple') }}</p>
                              </div>
                              <div class="flex-icon-block align-start">
                                <img :src="require('@/assets/module1/part2/lesson/reno-globale-ico.svg')"/>
                                <div>
                                  <p class="big-text">{{ $t('module1.part2.infography.block1.travaux.renoGlobale') }}</p>
                                  <p class="annotation" v-html="$t('module1.part2.infography.block1.aidesPrives.cee.renoGlobaleAnnotation')"></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="section">
                          <div class="section-head">
                            <img :src="require('@/assets/module1/part2/lesson/obtention-ico.svg')" :alt="$t('global.imageAlts.obtentionIllu')">
                            <p class="label">{{ $t('module1.part2.infography.block1.titles.obtention') }}</p>
                          </div>
                          <div class="section-content">
                            <div class="checklist">
                              <p class="item">{{ $t('module1.part2.infography.block1.aidesPrives.cee.obtention1') }}</p>
                              <p class="item">{{ $t('module1.part2.infography.block1.aidesPrives.cee.obtention2') }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="name === 'ecoptz'">
                        <div class="section">
                          <p class="section-content">
                            {{  $t('module1.part2.infography.block1.aidesPrives.ecoptz.intro') }}
                          </p>
                        </div>
                        <div class="section">
                          <div class="section-head">
                            <img :src="require('@/assets/module1/part2/lesson/logement-ico.svg')" :alt="$t('global.imageAlts.logementIllu')">
                            <p class="label" >{{ $t('module1.part2.infography.block1.titles.logement') }}</p>
                          </div>
                          <div class="section-content" v-html="$t('module1.part2.infography.block1.aidesPrives.ecoptz.logement')"></div>
                        </div>
                        <div class="section">
                          <div class="section-head">
                            <img :src="require('@/assets/module1/part2/lesson/qui-ico.svg')" :alt="$t('global.imageAlts.quiIllu')">
                            <p class="label" >{{ $t('module1.part2.infography.block1.titles.qui') }}</p>
                          </div>
                          <div class="section-content" v-html="$t('module1.part2.infography.block1.aidesPrives.ecoptz.qui')"></div>
                        </div>
                        <div class="section">
                          <div class="section-head">
                            <img :src="require('@/assets/module1/part2/lesson/money-ico.svg')" :alt="$t('global.imageAlts.moneyIllu')">
                            <p class="label" >{{ $t('module1.part2.infography.block1.titles.montant') }}</p>
                          </div>
                          <div class="section-content">
                            <p>{{ $t('module1.part2.infography.block1.aidesPrives.ecoptz.montant1') }}</p>
                            <p>{{ $t('module1.part2.infography.block1.aidesPrives.ecoptz.montant2') }}</p>
                            <p>{{ $t('module1.part2.infography.block1.aidesPrives.ecoptz.montant3') }}</p>
                            <p>{{ $t('module1.part2.infography.block1.aidesPrives.ecoptz.montant4') }}</p>
                            <p>{{ $t('module1.part2.infography.block1.aidesPrives.ecoptz.montant5') }}</p>
                          </div>
                        </div>
                        <div class="section">
                          <div class="section-head">
                            <img :src="require('@/assets/module1/part2/lesson/outils-ico.svg')" :alt="$t('global.imageAlts.travauxIllu')">
                            <p class="label" >{{ $t('module1.part2.infography.block1.titles.travaux') }}</p>
                          </div>
                          <div class="section-content">
                            <p class="left-content" v-html="$t('module1.part2.infography.block1.aidesPrives.ecoptz.travauxIntro')"></p>
                            <div class="list-wrapper">
                              <div class="flex-icon-block">
                                <img :src="require('@/assets/module1/part2/lesson/isolation-ico.svg')"/>
                                <p class="big-text">{{ $t('module1.part2.infography.block1.travaux.isol') }}</p>
                              </div>
                              <div class="flex-icon-block">
                                <img :src="require('@/assets/module1/part2/lesson/menuiserie-ico.svg')"/>
                                <p class="big-text">{{ $t('module1.part2.infography.block1.travaux.menuiserie') }}</p>
                              </div>
                              <div class="flex-icon-block">
                                <img :src="require('@/assets/module1/part2/lesson/chauffage-ico.svg')"/>
                                <p class="big-text">{{ $t('module1.part2.infography.block1.travaux.chauffage') }}</p>
                              </div>
                              <div class="flex-icon-block">
                                <img :src="require('@/assets/module1/part2/lesson/ventilation-ico.svg')"/>
                                <p class="big-text">{{ $t('module1.part2.infography.block1.travaux.vmc') }}</p>
                              </div>
                              <div class="flex-icon-block align-start">
                                <img :src="require('@/assets/module1/part2/lesson/reno-globale-ico.svg')"/>
                                <div>
                                  <p class="big-text">{{ $t('module1.part2.infography.block1.travaux.renoGlobale') }}</p>
                                  <p class="annotation" v-html="$t('module1.part2.infography.block1.aidesPrives.ecoptz.renoGlobaleAnnotation')"></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="section">
                          <div class="section-head">
                            <img :src="require('@/assets/module1/part2/lesson/obtention-ico.svg')" :alt="$t('global.imageAlts.obtentionIllu')">
                            <p class="label" >{{ $t('module1.part2.infography.block1.titles.obtention') }}</p>
                          </div>
                          <div class="section-content">
                            <div class="checklist">
                              <p class="item">{{ $t('module1.part2.infography.block1.aidesPrives.ecoptz.obtention') }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </app-tabs>
                </div>
              </template>
            </drawer>

            <!-- AIDES LOCALES -->
            <drawer>
              <template v-slot:head="{ isOpen }">
                <div class="sub-drawer-head aides-locales" :class="{ open: isOpen }">
                  <p class="sub-drawer-count">3</p>
                  <p class="title">{{ $t('module1.part2.infography.block1.aidesLocales.title') }}</p>
                  <div class="arrow">
                    <div class="icon" v-html="require('@/assets/icons/right-herringbone.svg?inline')"></div>
                  </div>
                </div>
              </template>
              <template v-slot:content>
                <div class="sub-drawer-content aides-locales primary">
                  <!-- AIDES LOCALES -->
                  <div class="head">
                    <p class="intro">
                      {{ $t('module1.part2.infography.block1.aidesLocales.intro') }}
                    </p>
                  </div>
                  <div class="section">
                    <div class="section-head">
                      <p class="label">{{ $t('module1.part2.infography.block1.aidesLocales.collectiviteTitle') }}</p>
                    </div>
                    <div class="section-content">{{ $t('module1.part2.infography.block1.aidesLocales.collectivite') }}</div>
                  </div>
                  <div class="section">
                    <div class="section-head">
                      <p class="label" >{{ $t('module1.part2.infography.block1.aidesLocales.dispoTitle') }}</p>
                    </div>
                    <div class="section-content">
                      <div class="checklist">
                        <div class="item"><p v-html="$t('module1.part2.infography.block1.aidesLocales.dispo1')"></p></div>
                        <div class="item"><p v-html="$t('module1.part2.infography.block1.aidesLocales.dispo2')"></p></div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </drawer>
          </div>
        </template>
      </drawer>
    </div>

    <!-- CUMUL DES AIDES -->
    <div class="main-drawer-wrapper">
      <drawer>
        <template v-slot:head="{ isOpen }">
          <div class="main-drawer-head secondary-dark" :class="{ open: isOpen }">
            <p class="title">{{ $t('module1.part2.infography.block2.title') }}</p>
            <div class="arrow">
              <div class="icon" v-html="require('@/assets/icons/right-herringbone.svg?inline')"></div>
            </div>
          </div>
        </template>
        <template v-slot:content>
          <div class="main-drawer-content secondary-dark">
            <!-- CUMUL MPR CEE -->
            <drawer class="cumul-mpr-cee">
              <template v-slot:head="{ isOpen }">
                <div class="sub-drawer-head" :class="{ open: isOpen }">
                  <div class="cumul-head">
                    <div class="logo-wrapper">
                      <img :src="require('@/assets/logos/aides/logo-mpr.svg')" :alt="$t('global.imageAlts.maprimerenovLogo')">
                    </div>
                    <p class="plus">
                      +
                    </p>
                    <div class="logo-wrapper">
                      <img :src="require('@/assets/logos/aides/logo-cee-cdp.svg')" :alt="$t('global.imageAlts.ceeLogo')">
                    </div>
                  </div>
                  <p class="title" v-html="$t('module1.part2.infography.block2.primeRenovCee.title')"></p>
                  <div class="arrow">
                    <div class="icon" v-html="require('@/assets/icons/right-herringbone.svg?inline')"></div>
                  </div>
                </div>
              </template>
              <template v-slot:content>
                <div class="sub-drawer-content">
                  <div class="section">
                    <div class="section-content" v-html="$t('module1.part2.infography.block2.primeRenovCee.intro')"></div>
                  </div>
                  <div class="section">
                    <div class="section-head">
                      <p class="big-text">{{ $t('module1.part2.infography.block2.primeRenovCee.cumul') }}</p>
                    </div>
                    <div class="section-content">
                      <div class="cumul-list">
                        <div class="item">
                          <div class="cumul-list-item-head">
                            <img :src="require('@/assets/module1/part2/lesson/small-money-ico.svg')" :alt="$t('global.imageAlts.moneyIllu')">
                            <p v-html="$t('module1.part2.infography.block2.primeRenovCee.cumul1')"></p>
                          </div>
                          <div class="cumul-list-item-content">
                            <img :src="require('@/assets/module1/part2/lesson/illu-cumul-mpr-cee-4.svg')" :alt="$t('global.imageAlts.regleCumulTresModeste')">
                          </div>
                        </div>
                        <div class="item">
                          <div class="cumul-list-item-head">
                            <img :src="require('@/assets/module1/part2/lesson/small-money-ico.svg')" :alt="$t('global.imageAlts.moneyIllu')">
                            <p v-html="$t('module1.part2.infography.block2.primeRenovCee.cumul2')"></p>
                          </div>
                          <div class="cumul-list-item-content">
                            <img :src="require('@/assets/module1/part2/lesson/illu-cumul-mpr-cee-3.svg')" :alt="$t('global.imageAlts.regleCumulModeste')">
                          </div>
                        </div>
                        <div class="item">
                          <div class="cumul-list-item-head">
                            <img :src="require('@/assets/module1/part2/lesson/small-money-ico.svg')" :alt="$t('global.imageAlts.moneyIllu')">
                            <p v-html="$t('module1.part2.infography.block2.primeRenovCee.cumul3')"></p>
                          </div>
                          <div class="cumul-list-item-content">
                            <img :src="require('@/assets/module1/part2/lesson/illu-cumul-mpr-cee-2.svg')" :alt="$t('global.imageAlts.regleCumulIntermediaire')">
                          </div>
                        </div>
                        <div class="item">
                          <div class="cumul-list-item-head">
                            <img :src="require('@/assets/module1/part2/lesson/small-money-ico.svg')" :alt="$t('global.imageAlts.moneyIllu')">
                            <p v-html="$t('module1.part2.infography.block2.primeRenovCee.cumul4')"></p>
                          </div>
                          <div class="cumul-list-item-content">
                            <img :src="require('@/assets/module1/part2/lesson/illu-cumul-mpr-cee-1.svg')" :alt="$t('global.imageAlts.regleCumulAise')">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="section">
                    <div class="section-content" v-html="$t('module1.part2.infography.block2.primeRenovCee.outro')"></div>
                  </div>
                </div>
              </template>
            </drawer>

            <!-- CUMUL NATIONAL LOCAL -->
            <drawer class="cumul-nat-loc">
              <template v-slot:head="{ isOpen }">
                <div class="sub-drawer-head" :class="{ open: isOpen }">
                  <div class="cumul-head">
                    <div class="logo-wrapper">
                      <img :src="require('@/assets/module1/part2/lesson/mpr-cee-logo.svg')" :alt="$t('global.imageAlts.maprimerenovLogo')">
                    </div>
                    <p class="plus">
                      +
                    </p>
                    <div class="logo-wrapper">
                      <img :src="require('@/assets/module1/part4/illu-tip-location.svg')" :alt="$t('global.imageAlts.pinHomeIllu')">
                    </div>
                  </div>
                  <p class="title">{{ $t('module1.part2.infography.block2.localNational.title') }}</p>
                  <div class="arrow">
                    <div class="icon" v-html="require('@/assets/icons/right-herringbone.svg?inline')"></div>
                  </div>
                </div>
              </template>
              <template v-slot:content>
                <div class="sub-drawer-content">
                  <div class="section">
                    <div class="section-content" v-html="$t('module1.part2.infography.block2.localNational.intro')"></div>
                  </div>
                </div>
              </template>
            </drawer>

            <!-- INCOMPATIBILITÉS AIDES -->
            <drawer class="cumul-incompat">
              <template v-slot:head="{ isOpen }">
                <div class="sub-drawer-head" :class="{ open: isOpen }">
                  <p class="title">{{ $t('module1.part2.infography.block2.incompatibilite.title') }}</p>
                  <div class="arrow">
                    <div class="icon" v-html="require('@/assets/icons/right-herringbone.svg?inline')"></div>
                  </div>
                </div>
              </template>
              <template v-slot:content>
                <div class="sub-drawer-content">
                  <div class="section">
                    <div class="section-content">
                      <p v-html="$t('module1.part2.infography.block2.incompatibilite.intro')"></p>
                      <br><br>
                      <div class="image-wrapper-incompatibilite">
                        <img :src="require('@/assets/module1/part2/lesson/illu-incompatibilite.svg')" :alt="$t('global.imageAlts.incompatibiliteIllu')">
                      </div>
                      <br><br>
                      <div class="training-warning align-center">
                        <img :src="require('@/assets/icons/warning.svg')" :alt="$t('global.imageAlts.warning')">
                        <p>{{ $t('module1.part2.infography.block2.incompatibilite.content') }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </drawer>
          </div>
        </template>
      </drawer>
    </div>

    <!-- AIDES PAR TRAVAUX -->
    <div class="main-drawer-wrapper">
      <drawer>
        <template v-slot:head="{ isOpen }">
          <div class="main-drawer-head secondary" :class="{ open: isOpen }">
            <p class="title">{{ $t('module1.part2.infography.block3.title') }}</p>
            <div class="arrow">
              <div class="icon" v-html="require('@/assets/icons/right-herringbone.svg?inline')"></div>
            </div>
          </div>
        </template>
        <template v-slot:content>
          <!-- AIDES PAR TRAVAUX -->
          <drawer>
            <template v-slot:head="{ isOpen }">
              <div class="sub-drawer-head by-travaux" :class="{ open: isOpen }">
                <p class="title">{{ $t('module1.part2.infography.block3.byTravaux.title') }}</p>
                <div class="arrow">
                  <div class="icon" v-html="require('@/assets/icons/right-herringbone.svg?inline')"></div>
                </div>
              </div>
            </template>
            <template v-slot:content>
              <div class="sub-drawer-content secondary by-travaux">
                <div v-for="categorie in categoriesTravaux"
                  :key="categorie.name"
                  class="section">
                  <div class="section-head">
                    <img :src="require(`@/assets/module1/part2/lesson/categories/${categorie.name}.svg`)" :alt="categorie.name">
                    <p class="label">{{ categorie.label }}</p>
                  </div>
                  <div class="section-content">
                    <drawer v-for="travail in categorie.travaux" :key="travail.name" class="travail-drawer">
                      <template v-slot:head="{ isOpen }">
                        <div class="travaux-drawer-head" :class="{ open: isOpen }">
                          {{ travail.label }}
                          <div class="arrow">
                            <img :src="require('@/assets/icons/right-herringbone.svg')" />
                          </div>
                        </div>
                      </template>
                      <template v-slot:content>
                        <div class="travaux-drawer-content">
                          <div v-for="aide in travail.aides"
                             :key="travail +  '-' + aide"
                            class="aide-col"
                            :class="{ 'big-col': aide.name === 'mpr' }"
                          >
                            <div class="logo-wrapper">
                              <img :src="require(`@/assets/logos/aides/logo-${aide.name}.svg`)" :alt="aide.name">
                            </div>
                            <p class="annotation" v-if="aide.isDependDecarbonation">{{ $t('module1.part2.infography.block3.byTravaux.mprDecarbonationCondition') }}</p>
                            <div class="ressources-wrapper" v-if="aide.ressources">
                              <p class="ressource-label">{{ $t('module1.part2.infography.block3.byTravaux.ressources') }}</p>
                              <div class="ressource-wrapper">
                                <p>{{ $t('module1.part2.infography.block3.byTravaux.tresModeste') }}</p>
                                <img v-if="aide.ressources.tresModeste" :src="require('@/assets/module1/part2/lesson/check-ico.svg')" :alt="$t('global.imageAlts.checkIcon')">
                                <img v-else :src="require('@/assets/module1/part2/lesson/cross-circled.svg')" :alt="$t('global.imageAlts.crossIcon')">
                              </div>
                              <div class="ressource-wrapper">
                                <p>{{ $t('module1.part2.infography.block3.byTravaux.modeste') }}</p>
                                <img v-if="aide.ressources.modeste" :src="require('@/assets/module1/part2/lesson/check-ico.svg')" :alt="$t('global.imageAlts.checkIcon')">
                                <img v-else :src="require('@/assets/module1/part2/lesson/cross-circled.svg')" :alt="$t('global.imageAlts.crossIcon')">
                              </div>
                              <div class="ressource-wrapper">
                                <p>{{ $t('module1.part2.infography.block3.byTravaux.intermediaire') }}</p>
                                <img v-if="aide.ressources.intermediaire" :src="require('@/assets/module1/part2/lesson/check-ico.svg')" :alt="$t('global.imageAlts.checkIcon')">
                                <img v-else :src="require('@/assets/module1/part2/lesson/cross-circled.svg')" :alt="$t('global.imageAlts.crossIcon')">
                              </div>
                              <div class="ressource-wrapper">
                                <p>{{ $t('module1.part2.infography.block3.byTravaux.aise') }}</p>
                                <img v-if="aide.ressources.aise" :src="require('@/assets/module1/part2/lesson/check-ico.svg')" :alt="$t('global.imageAlts.checkIcon')">
                                <img v-else :src="require('@/assets/module1/part2/lesson/cross-circled.svg')" :alt="$t('global.imageAlts.crossIcon')">
                              </div>
                            </div>
                            <div v-else class="status-wrapper">
                              <p v-if="typeof aide.status === 'string'">
                                {{ aide.status }}
                              </p>
                              <img v-else-if="aide.status" :src="require('@/assets/module1/part2/lesson/check-ico.svg')" :alt="$t('global.imageAlts.checkIcon')">
                              <img v-else :src="require('@/assets/module1/part2/lesson/cross-circled.svg')" :alt="$t('global.imageAlts.crossIcon')">
                            </div>
                          </div>
                        </div>
                      </template>
                    </drawer>
                  </div>
                </div>
              </div>
            </template>
          </drawer>

          <!-- AIDES RENO GLOBALE -->
          <drawer>
            <template v-slot:head="{ isOpen }">
              <div class="sub-drawer-head reno-globale" :class="{ open: isOpen }">
                <p class="title">{{ $t('module1.part2.infography.block3.renoGlobale.title') }}</p>
                <div class="arrow">
                  <div class="icon" v-html="require('@/assets/icons/right-herringbone.svg?inline')"></div>
                </div>
              </div>
            </template>
            <template v-slot:content>
              <div class="sub-drawer-content reno-globale">
                <!-- MPR ACCOMPAGNÉ -->
                <div class="head">
                  <div class="logo-wrapper">
                    <img :src="require('@/assets/logos/aides/logo-mpr.svg')" :alt="$t('global.imageAlts.maprimerenovLogo')">
                  </div>
                  <p class="intro">{{ $t('module1.part2.infography.block3.renoGlobale.mprAccompagne.title') }}</p>
                </div>
                <div class="section">
                  <div class="section-head">
                    <img :src="require('@/assets/module1/part2/lesson/qui-ico.svg')" :alt="$t('global.imageAlts.quiIllu')">
                    <p class="label" >{{ $t('module1.part2.infography.block1.titles.qui') }}</p>
                  </div>
                  <div class="section-content" v-html="$t('module1.part2.infography.block3.renoGlobale.mprAccompagne.qui')"></div>
                </div>
                <div class="section">
                  <div class="section-head">
                    <img :src="require('@/assets/module1/part2/lesson/obtention-ico.svg')" :alt="$t('global.imageAlts.obtentionIllu')">
                    <p class="label" >{{ $t('module1.part2.infography.block1.titles.obtention') }}</p>
                  </div>
                  <div class="section-content">
                    <div class="checklist">
                      <p class="item">{{ $t('module1.part2.infography.block3.renoGlobale.mprAccompagne.condition') }}</p>
                      <p class="item">{{ $t('module1.part2.infography.block3.renoGlobale.mprAccompagne.condition2') }}</p>
                      <p class="item">{{ $t('module1.part2.infography.block3.renoGlobale.mprAccompagne.condition3') }}</p>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="section-head">
                    <img :src="require('@/assets/module1/part2/lesson/money-ico.svg')" :alt="$t('global.imageAlts.moneyIllu')">
                    <p class="label" >{{ $t('module1.part2.infography.block1.titles.montant') }}</p>
                  </div>
                  <div class="section-content">
                    <div class="checklist">
                      <p class="item" v-html="$t('module1.part2.infography.block3.renoGlobale.mprAccompagne.montant1')"></p>
                      <p class="item" v-html="$t('module1.part2.infography.block3.renoGlobale.mprAccompagne.montant2')"></p>
                      <p class="item" v-html="$t('module1.part2.infography.block3.renoGlobale.mprAccompagne.montant3')"></p>
                      <p class="item" v-html="$t('module1.part2.infography.block3.renoGlobale.mprAccompagne.montant4')"></p>
                    </div>
                  </div>
                </div>

                <!-- ECO PTZ -->
                <div class="head">
                  <div class="logo-wrapper">
                    <img :src="require('@/assets/logos/aides/logo-ecoptz.svg')" :alt="$t('global.imageAlts.ecoPtzLogo')">
                  </div>
                  <p class="intro">{{ $t('module1.part2.infography.block3.renoGlobale.ecoptz.title') }}</p>
                </div>
                <div class="section">
                  <div class="section-head">
                    <img :src="require('@/assets/module1/part2/lesson/qui-ico.svg')" :alt="$t('global.imageAlts.quiIllu')">
                    <p class="label" >{{ $t('module1.part2.infography.block1.titles.qui') }}</p>
                  </div>
                  <div class="section-content" v-html="$t('module1.part2.infography.block3.renoGlobale.ecoptz.qui')"></div>
                </div>
                <div class="section">
                  <div class="section-head">
                    <img :src="require('@/assets/module1/part2/lesson/obtention-ico.svg')" :alt="$t('global.imageAlts.obtentionIllu')">
                    <p class="label" >{{ $t('module1.part2.infography.block1.titles.obtention') }}</p>
                  </div>
                  <div class="section-content">
                    <div class="checklist">
                      <p class="item">{{ $t('module1.part2.infography.block3.renoGlobale.ecoptz.condition') }}</p>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="section-head">
                    <img :src="require('@/assets/module1/part2/lesson/money-ico.svg')" :alt="$t('global.imageAlts.moneyIllu')">
                    <p class="label" >{{ $t('module1.part2.infography.block1.titles.montant') }}</p>
                  </div>
                  <div class="section-content">
                    <div class="checklist">
                      <p class="item" v-html="$t('module1.part2.infography.block3.renoGlobale.ecoptz.montant')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </drawer>
        </template>
      </drawer>
    </div>
  </div>
</template>

<script>
import Drawer from '@/components/Drawer'
import AppTabs from '@/components/AppTabs'

export default {
  name: 'Module1Part2Infography',
  components: { AppTabs, Drawer },
  data () {
    return {
      aidesEtatTabs: [
        {
          name: 'mpr',
          alt: this.$t('global.imageAlts.maprimerenovLogo'),
          image: require('@/assets/logos/aides/logo-mpr.svg')
        },
        {
          name: 'tva55',
          alt: this.$t('global.imageAlts.tva55'),
          image: require('@/assets/logos/aides/logo-tva55.svg')
        }
      ],
      aidesPriveTabs: [
        {
          name: 'cee',
          alt: this.$t('global.imageAlts.ceeLogo'),
          image: require('@/assets/logos/aides/logo-cee.svg')
        },
        {
          name: 'ecoptz',
          alt: this.$t('global.imageAlts.ecoPtzLogo'),
          image: require('@/assets/logos/aides/logo-ecoptz.svg')
        }
      ],
      categoriesTravaux: [
        {
          name: 'isolation',
          label: this.$t('module1.part2.infography.block3.byTravaux.categories.iso'),
          travaux: [
            {
              name: 'ite',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.ite'),
              aides: [
                {
                  name: 'mpr',
                  isDependDecarbonation: true,
                  ressources: {
                    tresModeste: true,
                    modeste: true,
                    intermediaire: true,
                    aise: false
                  }
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: true
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            },
            {
              name: 'iti',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.iti'),
              aides: [
                {
                  name: 'mpr',
                  isDependDecarbonation: true,
                  ressources: {
                    tresModeste: true,
                    modeste: true,
                    intermediaire: true,
                    aise: false
                  }
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: true
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            },
            {
              name: 'isoToitTerrasse',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.isoToitTerrasse'),
              aides: [
                {
                  name: 'mpr',
                  isDependDecarbonation: true,
                  ressources: {
                    tresModeste: true,
                    modeste: true,
                    intermediaire: true,
                    aise: false
                  }
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: true
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            },
            {
              name: 'isoComblesPerdus',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.isoComblesPerdus'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: false,
                    modeste: false,
                    intermediaire: false,
                    aise: false
                  }
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: true
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            },
            {
              name: 'isoRampants',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.isoRampants'),
              aides: [
                {
                  name: 'mpr',
                  isDependDecarbonation: true,
                  ressources: {
                    tresModeste: true,
                    modeste: true,
                    intermediaire: true,
                    aise: false
                  }
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: true
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            },
            {
              name: 'isoSol',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.isoSol'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: false,
                    modeste: false,
                    intermediaire: false,
                    aise: false
                  }
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: true
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            }
          ]
        },
        {
          name: 'chauffage',
          label: this.$t('module1.part2.infography.block3.byTravaux.categories.chauffage'),
          travaux: [
            {
              name: 'chaudiereGranule',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.chaudiereGranule'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: true,
                    modeste: true,
                    intermediaire: true,
                    aise: false
                  }
                },
                {
                  name: 'cdp',
                  status: true
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: true
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            },
            {
              name: 'pacGeo',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.pacGeo'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: true,
                    modeste: true,
                    intermediaire: true,
                    aise: false
                  }
                },
                {
                  name: 'cdp',
                  status: true
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: true
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            },
            {
              name: 'ssc',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.ssc'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: true,
                    modeste: true,
                    intermediaire: true,
                    aise: false
                  }
                },
                {
                  name: 'cdp',
                  status: true
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: true
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            },
            {
              name: 'chaudierePlaquette',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.chaudierePlaquette'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: true,
                    modeste: true,
                    intermediaire: true,
                    aise: false
                  }
                },
                {
                  name: 'cdp',
                  status: true
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: true
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            },
            {
              name: 'chaudiereBuche',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.chaudiereBuche'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: true,
                    modeste: true,
                    intermediaire: true,
                    aise: false
                  }
                },
                {
                  name: 'cdp',
                  status: true
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: true
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            },
            {
              name: 'pacAE',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.pacAE'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: true,
                    modeste: true,
                    intermediaire: true,
                    aise: false
                  }
                },
                {
                  name: 'cdp',
                  status: true
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: true
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            },
            {
              name: 'pacHybride',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.pacHybride'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: true,
                    modeste: true,
                    intermediaire: true,
                    aise: false
                  }
                },
                {
                  name: 'cdp',
                  status: true
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: false
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            },
            {
              name: 'pacAA',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.pacAA'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: false,
                    modeste: false,
                    intermediaire: false,
                    aise: false
                  }
                },
                {
                  name: 'cdp',
                  status: false
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: false
                },
                {
                  name: 'tva55',
                  status: false
                }
              ]
            },
            {
              name: 'cesi',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.cesi'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: true,
                    modeste: true,
                    intermediaire: true,
                    aise: false
                  }
                },
                {
                  name: 'cdp',
                  status: false
                },
                {
                  name: 'cee',
                  status: false
                },
                {
                  name: 'ecoptz',
                  status: true
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            },
            {
              name: 'poeleGranule',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.poeleGranule'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: true,
                    modeste: true,
                    intermediaire: true,
                    aise: false
                  }
                },
                {
                  name: 'cdp',
                  status: true
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: true
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            },
            {
              name: 'poeleBuche',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.poeleBuche'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: true,
                    modeste: true,
                    intermediaire: true,
                    aise: false
                  }
                },
                {
                  name: 'cdp',
                  status: true
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: true
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            },
            {
              name: 'chaudiereGaz',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.chaudiereGaz'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: false,
                    modeste: false,
                    intermediaire: false,
                    aise: false
                  }
                },
                {
                  name: 'cdp',
                  status: false
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: false
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            },
            {
              name: 'raccordReseauChaleur',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.raccordReseauChaleur'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: true,
                    modeste: true,
                    intermediaire: true,
                    aise: false
                  }
                },
                {
                  name: 'cdp',
                  status: true
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: true
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            },
            {
              name: 'raccordReseauFroid',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.raccordReseauFroid'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: true,
                    modeste: true,
                    intermediaire: true,
                    aise: false
                  }
                },
                {
                  name: 'cdp',
                  status: false
                },
                {
                  name: 'cee',
                  status: false
                },
                {
                  name: 'ecoptz',
                  status: true
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            },
            {
              name: 'chauffeEauThermo',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.chauffeEauThermo'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: true,
                    modeste: true,
                    intermediaire: true,
                    aise: false
                  }
                },
                {
                  name: 'cdp',
                  status: false
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: true
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            },
            {
              name: 'radiaElec',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.radiaElec'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: false,
                    modeste: false,
                    intermediaire: false,
                    aise: false
                  }
                },
                {
                  name: 'cdp',
                  status: false
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: false
                },
                {
                  name: 'tva55',
                  status: false
                }
              ]
            },
            {
              name: 'regulation',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.regulation'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: false,
                    modeste: false,
                    intermediaire: false,
                    aise: false
                  }
                },
                {
                  name: 'cdp',
                  status: false
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: this.$t('module1.part2.infography.block3.byTravaux.induit')
                },
                {
                  name: 'tva55',
                  status: this.$t('module1.part2.infography.block3.byTravaux.induit')
                }
              ]
            },
            {
              name: 'robinetThermo',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.robinetThermo'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: false,
                    modeste: false,
                    intermediaire: false,
                    aise: false
                  }
                },
                {
                  name: 'cdp',
                  status: true
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: this.$t('module1.part2.infography.block3.byTravaux.induit')
                },
                {
                  name: 'tva55',
                  status: this.$t('module1.part2.infography.block3.byTravaux.induit')
                }
              ]
            },
            {
              name: 'deposeCuveFioul',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.deposeCuveFioul'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: true,
                    modeste: true,
                    intermediaire: true,
                    aise: false
                  }
                },
                {
                  name: 'cdp',
                  status: false
                },
                {
                  name: 'cee',
                  status: false
                },
                {
                  name: 'ecoptz',
                  status: this.$t('module1.part2.infography.block3.byTravaux.induit')
                },
                {
                  name: 'tva55',
                  status: this.$t('module1.part2.infography.block3.byTravaux.induit')
                }
              ]
            }
          ]
        },
        {
          name: 'ventil',
          label: this.$t('module1.part2.infography.block3.byTravaux.categories.ventil'),
          travaux: [
            {
              name: 'vmc-double-flux',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.vmcDoubleFlux'),
              aides: [
                {
                  name: 'mpr',
                  isDependDecarbonation: true,
                  ressources: {
                    tresModeste: true,
                    modeste: true,
                    intermediaire: true,
                    aise: false
                  }
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: true
                },
                {
                  name: 'tva55',
                  status: true
                }
              ]
            },
            {
              name: 'vmc-simple-flux',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.vmcSimpleFlux'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: false,
                    modeste: false,
                    intermediaire: false,
                    aise: false
                  }
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: this.$t('module1.part2.infography.block3.byTravaux.induit')
                },
                {
                  name: 'tva55',
                  status: this.$t('module1.part2.infography.block3.byTravaux.induit')
                }
              ]
            },
            {
              name: 'vmc-hybride',
              label: this.$t('module1.part2.infography.block3.byTravaux.travaux.vmcHybride'),
              aides: [
                {
                  name: 'mpr',
                  ressources: {
                    tresModeste: false,
                    modeste: false,
                    intermediaire: false,
                    aise: false
                  }
                },
                {
                  name: 'cee',
                  status: true
                },
                {
                  name: 'ecoptz',
                  status: this.$t('module1.part2.infography.block3.byTravaux.induit')
                },
                {
                  name: 'tva55',
                  status: this.$t('module1.part2.infography.block3.byTravaux.induit')
                }
              ]
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.module1-part1-infography {
  .main-drawer-wrapper + .main-drawer-wrapper {
    margin-top: $space-s;
  }
  .primary {
    background-color: $c-primary;
  }
  .secondary {
    background-color: $c-secondary;
  }
  .secondary-dark {
    background-color: $c-text;
  }
  .sub-drawer-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    padding: $space-s;
    .sub-drawer-count {
      background-color: #fff;
      color: transparent;
      width: 30px;
      height: 30px;
      border-radius: $radius-s 0 $radius-s 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $fz-l;
    }
    .title {
      text-align: center;
      font-size: $fz-l;
      font-family: $ff-big;
      font-weight: $fw-s;
      margin: $space-s 0;
    }
    .intro {
      font-size: $fz-xs;
      font-weight: $fw-s;
      text-align: center;
      margin-bottom: $space-s;
    }
    .arrow {
      margin-top: $space-m;
      background-color: #fff;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.2s;
      .icon {
        position: relative;
        left: 1px;
        top: -1px;
        width: 14px;
        height: 19px;
      }
    }
    &.open {
      .arrow {
        transform: rotate(90deg);
      }
    }
  }
  .sub-drawer-content {
    .section + .head {
      border-top: 1px solid #fff;
      margin-top: $space-l;
      padding-top: $space-l;
    }
    .head {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $space-m $space-xs;
      .intro {
        text-align: center;
        color: #fff;
        font-weight: $fw-s;
        margin-top: $space-s;
      }
    }
    .section {
      background-color: #fff;
      border-radius: $radius-xl 0 0 0;
      padding: $space-sm $space-m;
      &:nth-child(odd) {
        background-color: $c-background;
      }
      .section-head {
        display: flex;
        align-items: center;
        margin-bottom: $space-s;
        .label {
          color: $c-secondary;
          font-family: $ff-big;
          font-size: $fz-xl;
          font-weight: $fw-s;
          margin-left: $space-s;
        }
      }
      .section-content {
        text-align: center;
        font-size: $fz-s;
        .list-wrapper {
          margin-top: $space-m;
        }
        .left-content {
          text-align: left;
        }
        .annotation {
          color: $c-secondary;
          font-size: $fz-xs;
          font-weight: $fw-s;
        }
        .checklist {
          .item + .item {
            margin-top: $space-m;
          }
          .item {
            display: flex;
            align-items: flex-start;
            text-align: left;
            padding-right: $space-m;
            &:before {
              content: '';
              flex-shrink: 0;
              background-image: url('../../../../assets/module1/part2/lesson/check-ico.svg');
              background-repeat: no-repeat;
              background-size: 14px;
              width: 15px;
              margin-right: $space-m;
              height: 20px;
            }
          }
        }
      }
    }
  }
  .sub-drawer-tabs {
    margin-top: $space-sm;
    .aide-tab-wrapper {
      padding-bottom: 3px;
      .aide-tab {
        background-color: $c-background;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        border-radius: $radius $radius 0 0;
        img {
          min-height: 60px;
          width: auto;
          max-width: 120px;
          max-height: 60px;
        }
      }
      &.current {
        padding-bottom: 0;
        .aide-tab {
          background-color: #fff;
        }
      }
    }
  }
  .primary.sub-drawer-content {
    border-top: 2px solid #007a3d;
  }
  .secondary.sub-drawer-content {
    border-top: 2px solid #006191;
  }
  .reno-globale.sub-drawer-content {
    border-top: 2px solid #00679d;
  }
  .cumul-mpr-cee .sub-drawer-content {
    border-top: 2px solid darken(#204b62, 5);
  }
  .cumul-nat-loc .sub-drawer-content {
    border-top: 2px solid darken(#497086, 5);
  }
  .cumul-incompat .sub-drawer-content {
    border-top: 2px solid darken(#5b8cab, 5);
  }
  .image-wrapper-incompatibilite {
    max-width: 320px;
    margin: 0 auto;
  }
  .aides-etat {
    background-color: #009c58;
    .sub-drawer-count {
      color: #009c58;
    }
  }
  .aides-prives {
    background-color: #00935d;
    .sub-drawer-count {
      color: #00935d;
    }
  }
  .aides-locales {
    background-color: #008962;
    .sub-drawer-count {
      color: #008962;
    }
  }
  .cumul-mpr-cee {
    background-color: #24546d;
    .section {
      &:nth-child(even) {
        background-color: $c-background;
      }
      &:nth-child(odd) {
        background-color: #fff;
      }
    }
  }
  .cumul-nat-loc {
    background-color: #497086;
  }
  .cumul-incompat {
    background-color: #5b8cab;
  }
  .cumul-head {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin-top: $space-s;
    .plus {
      color: #fff;
      font-size: 5rem;
      margin: 0 $space-s;
    }
    .logo-wrapper {
      background-color: #fff;
      height: 80px;
      display: flex;
      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
      }
    }
  }
  .cumul-list {
    .item + .item {
      margin-top: $space-xl;
    }
    .item {
      .cumul-list-item-head {
        display: flex;
        margin-bottom: $space-s;
        color: $c-secondary;
        img {
          margin-right: $space-s;
        }
      }
      .cumul-list-item-content {
        padding: $space-s $space-sm;
        background-color: #fff;
        border-radius: $radius 0 $radius 0;
        img {
          width: 100%;
        }
      }
    }
  }
  .by-travaux {
    background-color: #00679d;
    .title-annotation {
      font-size: $fz-s;
      margin-bottom: $space-s;
    }
    .section {
      .section-head {
        margin-bottom: $space-l;
        img {
          margin-right: $space-s;
        }
      }
    }
    .section-content {
      .travail-drawer + .travail-drawer {
        margin-top: $space-s;
      }
    }
    .travaux-drawer-head {
      background-color: #cee8f4;
      border-top-left-radius: $radius-xl;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $space-s $space-m;
      text-transform: uppercase;
      color: $c-secondary;
      .arrow {
        margin-top: $space-s;
        background-color: #fff;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        transition: transform 0.2s;
        padding: 3px;
        img {
          width: 100%;
        }
      }
      &.open {
        .arrow {
          transform: rotate(90deg);
        }
      }
    }
    .travaux-drawer-content {
      border-top: 2px solid #fff;
      background-color: #cee8f4;
      border-bottom-right-radius: $radius-xl;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
      .aide-col + .aide-col {
        border-left: 2px solid #fff;
      }
      .aide-col {
        padding: $space-s $space-xs;
        display: flex;
        flex-direction: column;
        align-items: center;
        &.big-col {
          grid-column-start: span 2;
        }
        .logo-wrapper {
          flex-shrink: 0;
          height: 60px;
          padding: 5px 10px;
          img {
            height: 100%;
            min-width: auto;
            width: 100%;
          }
        }
        .status-wrapper {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            flex-shrink: 0;
            width: 20px;
          }
        }
        .ressources-wrapper {
          margin-top: $space-s;
          text-align: left;
          padding: $space-xs;
          word-break: break-word;
          .ressource-label {
            text-decoration: underline;
            font-size: $fz-s;
            font-weight: $fw-s;
            margin-bottom: $space-m;
          }
          .ressource-wrapper + .ressource-wrapper {
            margin-top: $space-s;
          }
          .ressource-wrapper {
            font-size: $fz-xs;
            font-weight: $fw-s;
            display: flex;
            justify-content: space-between;
            padding-right: $space-s;
            img {
              flex-shrink: 0;
              width: 20px;
            }
          }
        }
      }
    }
  }
  .reno-globale {
    background-color: #006191;
    .title-annotation {
      font-size: $fz-s;
      margin-bottom: $space-s;
      text-align: center;
    }
  }
  .big-text {
    color: $c-secondary;
    font-weight: $fw-l;
  }
  .flex-icon-block {
    margin-top: $space-s;
    display: flex;
    align-items: center;
    &.align-start {
      align-items: flex-start;
    }
    img {
      margin-right: $space-m;
    }
    p {
      text-align: left;
    }
  }
  .logo-wrapper {
    margin-bottom: $space-s;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: $space-sm $space-m;
    border-radius: $radius-xl 0 $radius-xl 0;
    img {
      min-width: 80px;
      max-height: 80px;
    }
    .title {
      margin-top: $space-s;
      font-size: $fz-xs;
      font-weight: $fw-l;
      color: $c-text-dark;
    }
  }
}

@media (min-width: $bp-phone) {
  .module1-part1-infography {
    .sub-drawer-head {
      padding: $space-m;
    }
    .sub-drawer-content {
      padding: $space-s;
      .section {
        border-radius: $radius 0 0 0;
        .section-head {
          justify-content: center;
          margin-bottom: $space-m;
        }
        .section-content {
          .cumul-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: $space-m;
            .item {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $bp-tablet) {
  .module1-part1-infography {
    .sub-drawer-content {
      .section {
        .list-wrapper, .checklist:not(.single) {
          display: grid;
          grid-template-columns: repeat(2, 50%);
          gap: $space-s;
          .item {
            margin: 0 !important;
          }
        }
      }
    }
    .by-travaux .travaux-drawer-content .aide-col .logo-wrapper {
      padding: 10px 15px;
    }
  }
}
</style>

<style lang="scss">
.module1-part1-infography {
  .sub-drawer-tabs {
    .tabs-wrapper {
      .tabs {
        display: flex;
        align-items: flex-end;
        .tab-wrapper {
          flex: 1 1 0;
        }
        .tab-wrapper + .tab-wrapper {
          margin-left: 3px;
        }
      }
    }
  }
  .primary {
    .arrow {
      svg path {
        stroke: $c-primary;
      }
    }
  }
  .secondary {
    .arrow {
      svg path {
        stroke: $c-secondary;
      }
    }
  }
  .secondary-dark {
    .arrow {
      svg path {
        stroke: $c-secondary-dark;
      }
    }
  }
  .aides-etat {
    .arrow {
      svg path {
        stroke: #009c58;
      }
    }
  }
  .aides-prives {
    .arrow {
      svg path {
        stroke: #00935d;
      }
    }
  }
  .aides-locales {
    .arrow {
      svg path {
        stroke: #008962;
      }
    }
  }
  .cumul-mpr-cee {
    .arrow {
      svg path {
        stroke: #24546d;
      }
    }
  }
  .cumul-nat-loc {
    .arrow {
      svg path {
        stroke: #497086;
      }
    }
  }
  .cumul-incompat {
    .arrow {
      svg path {
        stroke: #5b8cab;
      }
    }
  }
  .by-travaux {
    .arrow {
      svg path {
        stroke: #00679d;
      }
    }
  }
  .reno-globale {
    .arrow {
      svg path {
        stroke: #006191;
      }
    }
  }
}
</style>
