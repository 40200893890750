<template>
  <step-lesson-layout :title="$t('module1.part2.infography.viewTitle')">
    <template v-slot:content>
      <module1-part2-infography></module1-part2-infography>
    </template>
  </step-lesson-layout>
</template>

<script>
import Module1Part2Infography from '@/components/training/module1/part2/Module1Part2Infography'
import StepLessonLayout from '@/components/training/StepLessonLayout'

export default {
  name: 'panoramaAidesInfography',
  components: { StepLessonLayout, Module1Part2Infography }
}
</script>

<style lang="scss" scoped>

</style>
